<template>
  <div class='case'>
    <!-- 案例 -->
    <div class="image">
      <img src="@/assets/case/20170327031818458.png" class="img" alt="">
    </div>
    <div class="list">
      <div class="item">
        <img class="item_img" src="@/assets/dynamic/1_1015143212a20.jpg" alt="">
        <div class="right">
          <div class="title">App没能杀死浏览器&nbsp;&nbsp;浏览器反而成为了App？</div>
          <br>
          <div class="content">随着原声App越来越多，绝大部分人获取信息的方式从Pc变成手机，尽管人们获取的信息的载体发生变化了。。。</div>
          <br>
          <div class="time">时间：2017年10月15日</div>
        </div>
      </div>

      <div class="item">
        <img class="item_img" src="@/assets/dynamic/1_101513560OC1.jpg" alt="">
        <div class="right">
          <div class="title">App没能杀死浏览器&nbsp;&nbsp;浏览器反而成为了App？</div>
          <br>
          <div class="content">随着原声App越来越多，绝大部分人获取信息的方式从Pc变成手机，尽管人们获取的信息的载体发生变化了。。。</div>
          <br>
          <div class="time">时间：2017年10月15日</div>
        </div>
      </div>
    </div>

    <div class="foot">共 1页2条记录</div>
  </div>
</template>

<script>
export default {
  name: 'vue',
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
</script>

<style scoped lang='less'>
.case{
  margin-top: 0.1493rem;
  .image{
    width: 100%;
    .img{
      width: 100%;
    }
  }
}
.list{
  margin-top: 0.2985rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1.25rem;

  .item{
    padding: 0.1493rem 0;
    display: flex;
    .item_img{
      width: 0.8209rem;
      height: 0.7463rem;
    }
    .right{
      border-left: 0.0075rem solid #eee;
      padding-left: 0.1493rem;
      margin-left: 0.1493rem;
      display: flex;
      flex-direction: column;
      font-size: 0.1194rem;
      box-sizing: border-box;
      .title{
        color: #767676;
      }
      .content{
        color: #b2b2b2;
        font-size: 0.1119rem;
      }
      .time{
        color: #b2b2b2;
      }

    }
  }
}

.foot{
  margin: 0.4478rem 0;
  width: 100%;
  text-align: center;
  font-size: 14px;
}
</style>
